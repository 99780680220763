@import 'assets/css/variables.scss';

.stake-list-container {
  .stake-list-header {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    .stake-list-header-filter {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      @include media-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .stake-list-header-filter-tab {
        display: flex;
        align-items: center;
        // margin-bottom: 16px;

        // @include media-up(xl) {
        //   margin-bottom: 0;
        // }

        .title {
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;

          text-transform: capitalize;
          white-space: nowrap;
          margin: 0 4px 0 0;

          @include media-up(xm) {
            margin: 0 10px 0 0;
          }

          @include media-up(md) {
            font-size: 24px;
          }
        }

        .ant-radio-group {
          height: 45px;
          padding: 3px;
          display: flex;
          align-items: center;
        }

        .ant-radio-button-wrapper {
          color: #fff;
          text-align: center;
          background: transparent;
          border: 1px solid #01fbff;
          border-radius: 10px;

          margin-right: 6px;
          min-width: 60px;
          height: 20px;
          line-height: 100%;
          padding: 0 10px;

          @include media-up(xm) {
            margin-right: 10px;
            min-width: 70px;
          }

          @include media-up(md) {
            padding: 10px 16px;
            min-width: 100px;
            height: 40px;
            line-height: 130%;
          }

          > span:last-child {
            font-size: 10px;

            @include media-up(md) {
              font-size: 16px;
            }
          }
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          display: none;
        }

        .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper {
          border-radius: 10px;
          background: #10aee5;
          border: 1px solid #01fbff;
          color: $text-main;
          font-weight: 600;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          z-index: 1;
          color: #1890ff;
          background: #fff;
          border-color: #1890ff;
        }
      }
      .stake-list-header-filter-tab-right {
        display: flex;
      }
      .stake-list-header-filter-switch {
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-right: 8px;
        p {
          white-space: nowrap;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: #fff;
          margin-right: 10px;
        }
        .ant-switch-handle {
          left: 4px;
          &::before {
            background: #d9d9d9;
            width: 15px;
            height: 15px;
          }
        }
        .ant-switch {
          background: #23242b;
          border: 1px solid #34384c;
          max-width: 37px;
          width: 100%;
          border: 1px solid #34384c;
          border-radius: 100px;
        }
        .ant-switch-checked {
          background: #23242b;
          border: 1px solid #34384c;
          .ant-switch-handle {
            left: calc(100% - 18px - 1px);
            &::before {
              background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
              width: 15px;
              height: 15px;
            }
          }
        }
      }
      .stake-list-header-filter-search {
        width: 100%;
        height: 32px;

        @include media-up(md) {
          max-width: 200px;
          height: auto;
        }

        @include media-up(xl) {
          max-width: 230px;
        }

        .search-wrapper {
          border-radius: 8px;
          background: #232530;

          input {
            &::placeholder {
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 14px;
              line-height: 130%;
              color: #656b7e;
            }
          }
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
      .filter-icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
        margin-right: 12px;
        background: #23242b;
        border: 1px solid #34384c;
        border-radius: 6px;

        display: flex;
        justify-content: center;

        cursor: pointer;
        svg {
          width: 18px;
        }
      }
    }
  }

  .stake-list-content {
    padding: 0 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 30px;
    padding-bottom: 10px;
    position: relative;

    .loading-list-stake {
      color: white;
      margin: 0 auto;
      width: 0;

      @include media-up(md) {
        width: 50%;
      }

      @include media-up(xl) {
        width: 100%;
      }

      .loading-list-stake-icon {
        text-align: center;
        display: flex;
        justify-content: center;
        transform: translate(100%, 10px);
      }
    }
    .infinite-scroll {
      overflow-x: hidden;
    }
    .infinite-scroll-component {
      overflow-x: hidden !important;
    }
    .nodata-listStaking {
      margin: 0 auto;
      padding: 200px 0;
    }

    .ant-spin-container {
      .ant-row > div {
        margin-bottom: 16px;
      }

      .ant-col {
        height: 100%;

        .ant-list-item {
          height: 100%;
        }
      }
    }
  }
}
