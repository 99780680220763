@import 'assets/css/variables.scss';

.card-balance {
  padding: 40px 20px;
  background: rgba(62, 60, 60, 0.4);
  border-radius: 30px;
  @include media-up(sm) {
    padding: 80px 40px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    .balance-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
    }
    .card-percent {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      svg {
        height: 16px;
        margin-right: 5px;
      }

      &[data-up='true'] {
        color: $success;
        fill: $success;
      }
      &[data-up='false'] {
        color: $warning;
        fill: $warning;
      }
    }
  }
  .card-content {
    padding-top: 20px;
    span {
      font-weight: 600;
      font-size: 39px;
      line-height: 120%;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
