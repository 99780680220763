@import 'assets/css/variables.scss';

.header-container-desktop {
  height: 80px;
  padding: 0;
  background: $secondary;
  position: relative;

  display: none;
  @include media-up(lg) {
    display: block;
    height: 100px;
  }

  .header-logo {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    img {
      width: 100%;
      width: 140px;
      object-fit: contain;
      margin-right: 10px;
    }
  }
  .header-desktop {
    width: 100%;
    height: 100%;
    max-width: 1300px;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .header-link {
      list-style: none;
      margin: 0;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      li {
        white-space: nowrap;
        width: 100%;
        height: 100%;
        padding: 0 30px;
        display: block;
        a {
          color: #ffffff;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      li.active {
        a {
          color: #ffffff;
        }
      }
    }
    .header-right {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      & .actions:last-child {
        margin-left: 15px;
      }

      .avatar-image {
        width: fit-content;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        & > div {
          width: 40px;
          height: 40px;
          padding: 2px;
          margin-right: 10px;
          border-radius: 100%;
          background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
          overflow: hidden;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        & > span {
          color: #fcfcfd;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          white-space: nowrap;
        }
      }
    }
  }
}
