@import 'assets/css/variables.scss';

$panel-width: 300px;

.private-layout-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: row nowrap;

  .panel-container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;

    width: $panel-width;
    background: rgba(62, 60, 60, 0.6);
    // backdrop-filter: blur(4px);
    border-radius: 0 12px 12px 0;
    display: none;
    @include media-up(lg) {
      display: block;
    }

    .panel-body {
      width: 100%;
      height: 100%;
      max-width: 160px;
      margin: 0 auto;
      padding: 10px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &::before {
        content: '';
        position: absolute;
        left: -100px;
        bottom: 3%;

        width: 300px;
        height: 400px;
        background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
        opacity: 0.3;
        filter: blur(100px);
        pointer-events: none;
      }

      .panel-top {
        .panel-logo {
          padding: 20px 0;
          cursor: pointer;
          img {
            width: 100%;
            max-width: 140px;
            object-fit: contain;
            margin-right: 10px;
          }
        }
        ul.panel-link {
          list-style: none;
          display: flex;
          flex-direction: column;
          row-gap: 26px;

          margin-top: 80px;

          li {
            a {
              color: #a7b7dd;
              fill: #a7b7dd;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;

              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;

              padding: 8px 10px;

              svg {
                width: 20px;
                margin-right: 16px;
              }
            }
          }
          li.active a,
          li a:hover {
            color: #2a4178;
            fill: #2a4178;
            background: #f8faff;
            box-shadow: 0px 4px 10px rgba(175, 137, 255, 0.15);
            border-radius: 8px;
          }
        }
      }
      .panel-footer {
        h5 {
          color: #ffffff;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
        p {
          color: #bfbfbf;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          margin-top: 10px;
        }
        .connect-now {
          button {
            fill: #2a4178;
            color: #2a4178;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;

            width: 100%;
            height: 40px;
            margin-top: 16px;
            background: #ffffff;
            border-radius: 8px;
            border: unset;
            cursor: pointer;

            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;

            &:hover {
              opacity: 0.7;
            }
            &:active {
              opacity: 1;
            }

            svg {
              margin-right: 8px;
            }
          }
        }

        .term-privacy {
          color: #b9b9b9;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: block;
          margin-top: 50px;

          &:hover {
            text-decoration: underline;
          }
        }

        .version {
          color: #b9b9b9;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-top: 50px;
        }
      }
    }
  }

  .private-content {
    width: 100%;
    height: 100vh;
    margin-left: auto;
    padding-bottom: 200px;

    position: relative;
    overflow: auto;

    @include media-up(lg) {
      width: calc(100% - $panel-width);
      padding-bottom: 0;
    }
  }
}
