@import 'assets/css/variables.scss';

.modal-staking-details {
  max-width: 100vw;
  margin: 0 auto;
  height: unset;
  padding: 0;
  height: auto !important;

  .loading {
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .taking-detail-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-up(md) {
      justify-content: flex-start;
    }

    > span:first-child {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;

      @include media-up(md) {
        font-size: 32px;
      }
    }

    > span:last-child {
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: $text-main;
      text-transform: capitalize;
      text-align: center;

      padding: 4px 10px;
      border-radius: 4px;
      height: 24px;
      min-width: 90px;

      @include media-up(md) {
        margin-left: 58px;
      }

      &[data-status='HOLDING'] {
        background-color: #ffb051;
      }

      &[data-status='UNSTAKE'] {
        background-color: #44c99c;
      }

      &[data-status='COMPLETED'] {
        background-color: #232530;
      }
    }
  }
  .ant-modal-content {
    padding: 24px;
    padding: 64px 12px 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include media-up(xm) {
      padding: 64px 24px 16px;
    }

    @include media-up(md) {
      border-radius: 12px;
    }

    .ant-modal-close {
      top: 30px;
      right: 24px;
    }

    .ant-modal-header {
      padding-left: 0;
      margin-bottom: 8px;
    }

    .ant-modal-body {
      padding: 0;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &.modal-staking-details-steps {
          counter-reset: section;
          margin-bottom: 24px;

          li {
            position: relative;
            min-height: 25px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            p {
              margin-bottom: 0;
              font-weight: 500;
              line-height: 27px;
              color: #ffffff;
              text-align: left;
              font-size: 14px;
              @media (min-width: 768px) {
                font-size: 16px;
              }
              &:last-child {
                // padding-bottom: 14px;
                font-size: 12px;
                font-weight: 400;
              }
            }

            // &::before {
            //   counter-increment: section;
            //   content: counter(section);
            //   font-weight: 700;
            //   font-size: 16px;
            //   line-height: 27px;
            //   color: #000000;
            //   height: 28px;
            //   width: 28px;
            //   background: #e6eaed;
            //   display: flex;
            //   justify-content: center;
            //   border-radius: 50%;
            //   position: absolute;
            //   z-index: 1;
            //   left: 0;
            //   top: 0;
            // }

            &:last-child {
              p {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        @media (min-width: 768px) {
          &.modal-staking-details-steps {
            counter-reset: section;

            li {
              margin-bottom: 16px;
              p {
                font-size: 16px;
                line-height: 130%;

                &:last-child {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .modal-staking-details-note {
        margin-bottom: 16px;
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          margin-bottom: 0;
          text-align: left;
          padding-left: 45px;

          &:first-child {
            color: #ffffff;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #00f4a3;
          }
        }

        @media (min-width: 991px) {
          p {
            font-size: 18px;
            line-height: 27px;
            &:last-child {
              font-size: 18px;
            }
          }
        }
      }

      .modal-staking-details-info {
        .modal-staking-details-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          // padding: 0 8px;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 0;

            &:first-child {
              font-weight: 500;
              font-size: 14px;
              text-align: left;
              margin-right: 16px;
            }

            &:last-child {
              text-align: right;
            }
          }

          &:nth-child(2) {
            p {
              &:last-child {
                font-weight: 500;
              }
            }
          }

          &:nth-child(4) {
            p {
              &:last-child {
                font-weight: 700;
                font-size: 16px;
                line-height: 21px;
                color: #ffffff;

                border-radius: 3px;
                min-width: 100px;
                min-height: 24px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;

            p {
              &:last-child {
                line-height: 16px;
                color: #ffffff;
                word-break: break-all;
                word-wrap: break-word;
                text-align: right;
              }
            }
          }
        }

        @media (min-width: 768px) {
          .modal-staking-details-info-item {
            margin-bottom: 16px;

            p {
              font-size: 16px;
              line-height: 130%;

              &:first-child {
                font-size: 16px;
              }
            }

            &:nth-child(5) {
              p {
                &:last-child {
                  line-height: 16px;
                }
              }
            }
          }
        }
      }

      .modal-staking-details-btn {
        width: 100%;
        margin: 24px 0 0;

        display: flex;
        justify-content: center;

        @include media-up(lg) {
          margin: 24px 0;
        }

        button {
          color: $text-main;
          max-width: 150px;
          background: linear-gradient(90deg, #0075fe 0%, #00c2fe 100%);
        }

        .button-primary[disabled] {
          opacity: 1;
          background: #a7b7dd;
        }
      }
    }

    .modal-scan-action-bg {
      position: absolute;
      height: 70%;
      width: 70%;
      // background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.9;
      filter: blur(250px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: 991px) {
      padding: 8px 24px;
    }
  }
}
// .ant-modal-wrap {
//   top: unset;
//   overflow: hidden;
//   // padding-bottom: 50px;
//   @media (min-width: 768px) {
//     top: 0;
//     overflow: auto;
//     padding-bottom: 0;
//   }
// }
