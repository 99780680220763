.warp-title-section {
  margin-bottom: 30px;
  text-align: center;

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #ffffff;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    margin-bottom: 0;
  }

  @media (min-width: 991px) {
    margin-bottom: 46px;

    h2 {
      font-size: 48px;
      line-height: 72px;
      margin-bottom: 32px;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
