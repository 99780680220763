@import 'assets/css/variables.scss';

.staking-card-item {
  // min-height: 540px;
  height: 100%;
  padding: 24px 32px;
  background: #4a4b4d4d;
  // backdrop-filter: blur(200px);
  border-radius: 15px;
  min-width: 270px;

  position: relative;

  @include media-up(xm) {
    padding: 24px 62px;
  }

  @include media-up(md) {
    padding: 24px 30px;
  }

  @media (min-width: 1290px) {
    max-width: 300px;
  }

  .staking-card-item-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: auto;
      height: 24px;
      object-fit: contain;
      margin-right: 8px;

      @include media-up(md) {
        width: auto;
        height: 54px;
        object-fit: contain;
        margin-right: 16px;
      }
    }

    .staking-helper-title {
      color: $text-main;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      margin-right: 10px;

      @include media-up(md) {
        font-size: 16px;
        margin-right: 24px;
      }
    }

    .staking-helper-loo {
      color: #f9aa4b;
      font-weight: 400;
      font-size: 10px;
      line-height: 130%;
      text-align: center;

      width: 100%;
      max-width: 80px;
      padding: 4px;
      background: #ffe6c8;
      border-radius: 5px;

      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -4px;
        top: 50%;
        transform: translateY(-50%);
        width: 0px;
        height: 9px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 4px solid #ffe6c8;
      }
    }
  }

  .staking-card-item-des {
    color: $text-main;
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    margin-top: 16px;

    @include media-up(md) {
      font-size: 16px;
    }
  }

  .staking-card-item-dup-logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin: 32px auto;

    @include media-up(md) {
      margin: 48px auto;
    }

    & > div:last-child {
      margin-left: -10px;
      z-index: 2;
    }

    img {
      width: auto;
      height: 62px;
      object-fit: contain;

      @include media-up(md) {
        width: auto;
        height: 54px;
        object-fit: contain;
      }
    }
  }
  .stake-volume {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    color: #fff;
    margin-bottom: 12px;
    & > * {
      white-space: nowrap;
    }
  }
  .duration-wrapper {
    margin-bottom: 48px;

    .staking-card-item-duration {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
      row-gap: 4px;
      margin-bottom: 8px;

      @include media-up(md) {
        min-height: 56px;
      }

      .staking-card-item-duration-text {
        color: $text-main;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        white-space: nowrap;
        margin-bottom: 4px;

        display: flex;
        align-items: center;

        @include media-up(md) {
          font-size: 14px;
        }
      }
      .staking-card-item-duration-number {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > div {
          padding: 4px 6px;
          margin-left: 8px;
          background-color: #232530;
          border-radius: 8px;
          cursor: pointer;

          @include media-up(md) {
            padding: 6px 10px;
          }

          &[data-active='true'] {
            background: #1a1f32;

            span {
              color: #567dff;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          span {
            color: #656b7e;
            font-weight: 400;
            font-size: 12px;
            line-height: 130%;

            display: flex;
            justify-content: center;
            align-items: center;

            min-width: 18px;
            border-radius: 8px;

            @include media-up(md) {
              font-size: 16px;
            }
          }
        }
      }
    }
    .wards-list {
      overflow: auto;
      max-height: 110px;

      @include media-up(md) {
        max-height: 105px;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      .staking-card-item-note {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        span {
          color: $text-main;
          font-weight: 400;
          line-height: 130%;
          font-size: 12px;

          @include media-up(md) {
            font-size: 14px;
          }
        }

        span:last-child {
          font-size: 12px;

          @include media-up(md) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .staking-card-item-actions {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;

    button {
      width: 50%;
      background: linear-gradient(90deg, #0075fe 0%, #00c2fe 100%);
      color: $text-main;
      padding: 10px 20px;

      @include media-up(md) {
        width: 55%;
      }
    }
  }
}
