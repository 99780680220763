@import 'assets/css/variables.scss';

.wrapper-form-signup {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  overflow: auto;

  @include media-up(md) {
    padding-top: 40px;
  }

  .form-signup {
    width: 100%;
    max-width: unset;
    margin: 0 auto;

    @include media-up(md) {
      max-width: 580px;
    }
    .form-action {
      width: 100%;
      max-width: 187px;
      margin: 0 auto;

      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      @include media-up(sm) {
        max-width: 287px;
      }
    }

    .form-links {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      a {
        color: $link;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin-left: 5px;

        @include media-up(sm) {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.7;
        }
        &:active {
          opacity: 1;
        }
      }
    }
  }
  .verify-code {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
