@import 'assets/css/variables.scss';

.auth-container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - $menu-height-mobile);
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  .auth-content-left {
    width: 100%;
    min-height: unset;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    &::after {
      content: '';
      position: absolute;
      top: 40%;
      left: 44%;
      transform: translate(-50%, -50%);

      width: 300px;
      height: 300px;
      background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.3;
      filter: blur(100px);
      pointer-events: none;
    }
    &::before {
      content: '';
      position: absolute;
      top: 60%;
      left: 58%;
      transform: translate(-50%, -50%);

      width: 300px;
      height: 300px;
      background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.3;
      filter: blur(100px);
      pointer-events: none;
    }

    @include media-up(md) {
      min-height: 100vh;
    }
    @include media-up(lg) {
      width: 40%;
    }
    @include media-up(xxl) {
      width: 50%;
    }

    .auth-content-img {
      width: 100%;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include media-up(md) {
        padding-top: 100px;
      }
      img {
        max-width: 340px;
        height: auto;
        object-fit: contain;
      }
    }

    .auth-actions {
      width: 100%;
      height: 50%;
      display: block;
      @include media-up(md) {
        display: none;
        padding-top: 50px;
      }
      & > a {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 20px auto;
      }
    }
  }
  .auth-content-right {
    width: 100%;
    max-width: 100%;
    min-height: unset;
    background: rgba(62, 60, 60, 1);
    // backdrop-filter: blur(4px);
    overflow: hidden;
    z-index: 3;

    position: absolute !important;
    right: 0;
    bottom: 0;

    // backdrop-filter: blur(4px);
    border-radius: 36px 36px 0px 0px;

    @include media-up(lg) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      width: 100%;
      max-width: 800px;
      min-height: 100vh !important;
      border-radius: 36px 0px 0px 36px;
    }
    @include media-up(xl) {
      width: 60% !important;
      max-width: 100%;
    }
    @include media-up(xxl) {
      width: 50% !important;
      max-width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 30%;
      left: 54%;
      transform: translate(-50%, -50%);

      width: 400px;
      height: 400px;
      background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.2;
      filter: blur(100px);
      pointer-events: none;

      display: none;
      @include media-up(md) {
        display: block;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 80%;
      left: 68%;
      transform: translate(-50%, -50%);

      width: 600px;
      height: 600px;
      background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.2;
      filter: blur(100px);
      pointer-events: none;

      display: none;
      @include media-up(md) {
        display: block;
      }
    }

    .auth-drag {
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
      touch-action: none;
      @include media-up(lg) {
        display: none;
      }
      .auth-drag-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: row-resize;
        > div {
          width: 80px;
          height: 6px;
          background: #f6f4fe;
          border-radius: 10px;
          box-shadow: 0px 1px 2px 1px rgb(255 255 255 / 50%);
        }
      }
    }

    .auth-body-right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
