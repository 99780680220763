@import 'assets/css/variables.scss';

.private-header-mobile {
  height: 80px;
  padding: 0 15px;
  background: $secondary;
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @include media-up(md) {
    height: 100px;
    padding: 0 40px;
  }

  .header-left {
    .header-logo {
      cursor: pointer;
      img {
        width: 100%;
        max-width: 140px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
  .header-right {
    display: flex;
    flex-flow: row nowrap;
    .avatar-image {
      width: fit-content;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      cursor: pointer;
      & > div {
        width: 40px;
        height: 40px;
        padding: 2px;
        margin-right: 10px;
        border-radius: 100%;
        background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
        overflow: hidden;
      }
      span:first-child {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 100%;
        background: #000;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        margin-right: 20px;
      }
      span:last-child {
        color: #fcfcfd;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        white-space: nowrap;
      }
    }
    .select-chain {
      height: 100%;
      width: 120px;
      margin-right: 22px;
      .ant-select-selector {
        background: transparent;
        border-radius: 10px;
      }
      .ant-select-selection-item {
        color: #fff;
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
    .collapse-icon {
      display: flex;
      align-items: center;
      transform: scale(-1);
      margin-left: 20px;
      @include media-up(lg) {
        display: none;
      }
      input#menu {
        display: none;
      }

      .icon {
        width: 34px;
        height: auto;
        padding: 0;
        display: block;
        cursor: pointer;
      }

      .icon .menu,
      .icon .menu::before,
      .icon .menu::after {
        background: #fff;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        transition: ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
      }
      .icon .menu::before {
        width: 28px;
      }
      .icon .menu {
        width: 24px;
      }
      .icon .menu::after {
        width: 34px;
      }

      .icon:hover .menu,
      .icon:hover .menu::before,
      .icon:hover .menu::after {
        background: #fff;
      }

      .icon .menu::before {
        top: -8px;
      }

      .icon .menu::after {
        top: 8px;
      }

      #menu:checked + .icon .menu {
        background: transparent;
      }

      #menu:checked + .icon .menu::before {
        width: 28px;
        transform: rotate(45deg);
      }

      #menu:checked + .icon .menu::after {
        width: 28px;
        transform: rotate(-45deg);
      }

      #menu:checked + .icon .menu::before,
      #menu:checked + .icon .menu::after {
        top: 0;
        transition: top ease 0.3s, transform ease 0.3s 0.3s;
      }
    }
  }
}

/*
* Menu content mobile
*/
.menu-content-mobile {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 0px;
  background: $secondary;
  z-index: 10;
  transition: ease-in-out 0.4s;
  opacity: 0;
  overflow: auto;

  visibility: hidden;
  &[data-visible='true'] {
    visibility: unset;
    height: 100%;
    opacity: 1;
  }

  .menu-body {
    min-height: 500px;
  }

  /*
  * Menu mobile Container 
  */
  ul.menu-link {
    list-style: none;
    margin: 16px 0 0;
    flex-flow: column nowrap;

    li {
      white-space: nowrap;
      padding: 16px 30px;
      a {
        color: rgba(93, 101, 136, 1);

        font-weight: 600;
        font-size: 18px;
        line-height: 160%;

        width: 100%;
        display: block;
        align-items: center;
        text-align: center;
      }
    }
    li.active {
      position: relative;
      a {
        color: #ffffff;
      }
    }
  }

  .menu-actions-mobile {
    width: 100%;
    padding: 30px 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    a {
      width: 100%;
      max-width: 127px;
      display: block;
    }
    & > a:first-child {
      margin-right: 10px;
    }
    & > a:last-child {
      margin-left: 10px;
    }
  }
}
