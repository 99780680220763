.modal-change-password {
  .ant-modal-content {
    p {
      text-align: left;
    }

    .ant-modal-body {
      padding: 0;

      p {
        margin-bottom: 8px;
      }
    }
  }
}
