.warp-support {
  position: relative;
  width: 100%;
  padding: 40px 16px;
  z-index: 1;

  .halo {
    position: absolute;
    top: 50%;
    left: 0;
    width: 80%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
    animation: opacity 5s infinite;
  }

  .warp-support-content {
    position: relative;
    max-width: 800px;
    margin: auto;
    z-index: 1;

    .support-item {
      background: rgba($color: #ebe6fa, $alpha: 0.05);
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 11.1556px;
      height: 100%;
      padding: 24px 20px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        max-height: 40px;
        max-width: 120px;
        object-fit: contain;
      }
    }
  }
  @media (min-width: 991px) {
    padding: 60px 24px;

    .warp-support-content {
      .support-item {
        padding: 45px 26px;
        img {
          max-height: 60px;
          max-width: 220px;
        }
      }
    }
  }
}
