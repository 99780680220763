@import 'assets/css/variables.scss';

.auth-card {
  width: 100%;
  padding: 30px 14px;
  border-radius: 12px;
  z-index: 2;

  @include media-up(sm) {
    padding: 60px 40px 10px;
  }
}
