.box-amount {
  margin-bottom: 32px;

  .amount-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 24px;
  }

  .amount-avaiable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-bottom: 8px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #a5adcf;

      &:first-child {
        color: #ef466f;
      }

      span {
        font-weight: 600;
        line-height: 27px;
        color: #ffffff;
      }
    }
  }

  .box-input-amount {
    position: relative;
    margin-bottom: 8px;

    input {
      height: 43px;
      border: 1px solid #34384c;
      box-sizing: border-box;
      border-radius: 5px;
      color: #ffffff;
      background: transparent;
      padding: 10px 16px;
      padding-right: 60px;
      width: 100%;
      outline: none;
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }

    button {
      border: none;
      outline: none;
      background: transparent;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #ffffff;
      padding: 8px 16px;
      cursor: pointer;
    }
  }

  .amount-fee {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #a5adcf;
      margin-bottom: 0;

      span {
        font-weight: 700;
        color: #ffffff;
      }
    }
  }

  @media (min-width: 991px) {
    margin-bottom: 48px;

    .amount-title {
      line-height: 27px;
      margin-bottom: 16px;
    }

    .amount-avaiable {
      margin-bottom: 12px;
    }
    .box-input-amount {
      margin-bottom: 12px;
    }
  }
}
