.modal-deposit-details {
  .ant-modal-content {
    padding-left: 50px;
    padding-right: 50px;

    .ant-modal-header {
      padding-left: 0;
      margin-bottom: 48px;
    }

    .ant-modal-body {
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        &.modal-deposit-details-steps {
          counter-reset: section;

          li {
            position: relative;
            min-height: 52px;
            padding-left: 45px;
            display: flex;
            flex-direction: column;

            p {
              margin-bottom: 0;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              color: #ffffff;
              text-align: left;

              &:last-child {
                padding-bottom: 14px;
                font-size: 12px;
                font-weight: 400;
              }
            }

            &::before {
              counter-increment: section;
              content: counter(section);
              font-weight: 700;
              font-size: 16px;
              line-height: 27px;
              color: #000000;
              height: 28px;
              width: 28px;
              background: #e6eaed;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              z-index: 1;
              left: 0;
              top: 0;
            }

            &::after {
              content: '';
              width: 1px;
              height: 100%;
              position: absolute;
              z-index: 0;
              left: 14px;
              top: 0;
              background-color: #ffffff;
            }

            &:last-child {
              &::after {
                content: none;
              }

              p {
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        @media (min-width: 991px) {
          &.modal-deposit-details-steps {
            counter-reset: section;

            li {
              p {
                font-size: 18px;
                line-height: 27px;

                &:last-child {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .modal-deposit-details-note {
        margin-bottom: 48px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          margin-bottom: 0;
          text-align: left;
          padding-left: 45px;

          &:first-child {
            color: #ffffff;
          }

          &:last-child {
            font-size: 12px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #00c2fe;
          }
        }

        @media (min-width: 991px) {
          p {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }

      .modal-deposit-details-info {
        .modal-deposit-details-info-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          p {
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 0;

            &:first-child {
              font-weight: 600;
              font-size: 14px;
              text-align: left;
              margin-right: 16px;
            }

            &:last-child {
              text-align: right;
            }
          }

          &:nth-child(2) {
            p {
              &:last-child {
                font-weight: 600;
              }
            }
          }

          &:nth-child(4) {
            p {
              &:last-child {
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                background: #d5f3e9;
                border-radius: 3px;
                color: #44c99c;
                min-width: 100px;
                min-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;

            p {
              &:last-child {
                line-height: 16px;
                color: #00c2fe;
                word-break: break-all;
                word-wrap: break-word;
                text-align: right;
              }
            }
          }
        }

        @media (min-width: 991px) {
          .modal-deposit-details-info-item {
            margin-bottom: 24px;

            p {
              font-size: 16px;
              line-height: 24px;

              &:first-child {
                font-size: 18px;
              }
            }

            &:nth-child(5) {
              p {
                &:last-child {
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .modal-scan-action-bg {
      position: absolute;
      height: 70%;
      width: 70%;
      background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
      opacity: 0.9;
      filter: blur(250px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    @media (min-width: 991px) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}
