@import 'assets/css/variables.scss';

.Datepicker-custom {
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 8px;
    @include media-up(lg) {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  > .ant-picker {
    height: 43px;
    border: 1px solid #34384c;
    box-sizing: border-box;
    border-radius: 5px;
    color: #ffffff;
    background: transparent;
    padding: 10px 16px;
    width: 100%;
    outline: none;
    input {
      color: #fff;
    }
    .anticon-calendar svg {
      fill: #fff;
    }
  }
}
.ant-form-item-has-error .Datepicker-custom .ant-picker,
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  border: 1px solid #ff4d4f !important;
  background: transparent !important;
}
