@import 'assets/css/variables.scss';

$pText: #fff;
$pTextActive: #01fbff;
$pBackground: transparent;

.ant-table-wrapper {
  .ant-table {
    background: $pBackground;
    thead {
      tr > th {
        color: #5d6588;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: transparent;
        border: unset;
      }
    }
    tbody {
      tr {
        &:hover {
          td {
            background: transparent !important;
          }
        }
        td {
          color: $pText;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          background: transparent;
          border: unset;
        }
      }
    }
    .ant-empty-description {
      color: $pText;
    }
  }
  ul.ant-pagination {
    color: $pText;
    li,
    button {
      border-radius: 5px;
    }

    .ant-pagination-prev button,
    .ant-pagination-next button {
      color: $pText;
      background: transparent;
      &:hover {
        border: 1px solid $pTextActive;
      }
    }
    .ant-pagination-item-ellipsis {
      color: #333;
    }
    .ant-pagination-item {
      border: 1px solid $pText;
      background: transparent;
      @include media-down(md) {
        margin-left: 5px;
      }

      a {
        color: $pText;
      }
    }
    .ant-pagination-item-active {
      background: $pBackground;
      border: 1px solid $pTextActive;
      a {
        color: $pTextActive;
      }
    }

    .ant-select-selector {
      color: $pText;
      border-radius: 5px;
      border: unset;
      overflow: hidden;
    }
    .ant-select-item {
      color: $pText;
    }
  }
}
