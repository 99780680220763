.box-wallet {
  position: relative;
  max-width: 500px;

  img {
    &.wallet-item {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:nth-child(1) {
        width: 50%;
        transform: translate(-55%, -60%);
        z-index: 1;
      }

      &:nth-child(2) {
        width: 13%;
        transform: translate(-385%, 45%);
        animation: rotateWallet 15s linear infinite;
      }

      &:nth-child(3) {
        width: 13%;
        transform: translate(284%, -145%);
        animation: rotateWallet2 15s linear infinite;
      }
    }

    &:not(.wallet-item) {
      width: 100%;
    }
  }
}

@keyframes rotateWallet {
  0% {
    transform: translate(-385%, 45%) scale(1);
    z-index: 2;
  }
  6.25% {
    transform: translate(-301.25%, 102%);
  }
  12.5% {
    transform: translate(-217.5%, 112%);
  }
  18.75% {
    transform: translate(-133.75%, 106%);
  }
  25% {
    transform: translate(-50%, 90%);
  }
  31.25% {
    transform: translate(33.5%, 69%);
  }
  37.5% {
    transform: translate(117%, 36%);
  }
  43.75% {
    transform: translate(200.5%, -9%) scale(1);
  }
  50% {
    transform: translate(284%, -145%) scale(0.9);
    z-index: 0;
  }
  56.25% {
    transform: translate(200.5%, -189%) scale(0.8);
  }
  62.5% {
    transform: translate(117%, -202%) scale(0.7);
  }
  68.75% {
    transform: translate(33.5%, -200%) scale(0.6);
  }
  75% {
    transform: translate(-50%, -185%) scale(0.7);
  }
  81.25% {
    transform: translate(-133.75%, -164%) scale(0.8);
  }
  87.5% {
    transform: translate(-217.5%, -135%) scale(0.9);
  }
  93.75% {
    transform: translate(-301.25%, -89%) scale(1);
  }
  100% {
    transform: translate(-385%, 45%) scale(1);
  }
}

@keyframes rotateWallet2 {
  0% {
    transform: translate(284%, -145%) scale(0.9);
  }
  6.25% {
    transform: translate(200.5%, -189%) scale(0.8);
  }
  12.5% {
    transform: translate(117%, -202%) scale(0.7);
  }
  18.75% {
    transform: translate(33.5%, -200%) scale(0.6);
  }
  25% {
    transform: translate(-50%, -185%) scale(0.7);
  }
  31.25% {
    transform: translate(-133.75%, -164%) scale(0.8);
  }
  37.5% {
    transform: translate(-217.5%, -135%) scale(0.9);
  }
  43.75% {
    transform: translate(-301.25%, -89%) scale(1);
  }
  50% {
    transform: translate(-385%, 45%) scale(1);
    z-index: 0;
  }
  56.25% {
    transform: translate(-301.25%, 102%);
  }
  62.5% {
    transform: translate(-217.5%, 112%);
  }
  68.75% {
    transform: translate(-133.75%, 106%);
  }
  75% {
    transform: translate(-50%, 90%);
  }
  81.25% {
    transform: translate(33.5%, 69%);
  }
  87.5% {
    transform: translate(117%, 36%);
  }
  93.75% {
    transform: translate(200.5%, -9%) scale(1);
  }
  100% {
    transform: translate(284%, -145%) scale(0.9);
    z-index: 2;
  }
}
