@import 'assets/css/variables.scss';

.history-box-recent-transaction {
  margin-top: 16px;
  background-color: transparent;
  border-radius: 12px;

  @include media-up(md) {
    padding: 16px;
  }

  @include media-up(lg) {
    padding: 48px 24px 24px;
    background-color: #111010;
    margin-top: 32px;
  }
}
