@import 'assets/css/variables.scss';

.WTransactionMobile {
  width: 100%;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #fff;

  @include media-up(md) {
    padding: 0 8px 16px;
  }
  > .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
    .left {
      color: #5d6588;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      font-weight: 400;
      font-size: 14px;
    }
    .right {
      p {
        color: #fff;
      }
    }

    .box-status {
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: $text-main;
      text-transform: capitalize;

      padding: 4px 10px;
      border-radius: 4px;
      height: 24px;
      max-width: 90px;

      &[data-status='HOLDING'] {
        background-color: #ffb051;
      }

      &[data-status='UNSTAKE'] {
        background-color: #44c99c;
      }

      &[data-status='COMPLETED'] {
        background-color: #232530;
      }
    }

    .currency {
      display: flex;
      align-items: center;
      .logo {
        width: 16px;
        max-width: 16px;
        object-fit: contain;
        margin-right: 6px;
      }
      .name {
        color: #fff;
      }
    }

    .btn-unstake {
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: $text-main;
      text-transform: capitalize;
      padding: 4px 10px;
      border-radius: 4px;
      height: 24px;
      max-width: 90px;
      background-color: #ffb051;
    }
  }
}
