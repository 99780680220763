.total-balance {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // min-width: 320px;

  & > div {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    width: fit-content;
    height: 40px;
    padding: 0 16px;
    border: 1px solid #535769;
    border-radius: 30px;
    margin-right: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & > span:first-child {
      margin-right: 10px;
      white-space: nowrap;
    }
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
}
