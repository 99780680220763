@import 'assets/css/variables.scss';

.TableRecentTransactionDesktop {
  .ant-table {
    background: transparent;
  }

  /* Scrollbar */
  .ant-table-body {
    overflow: auto !important;
    &::-webkit-scrollbar {
      width: 5px;
      background: #003535;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      width: 3px;
    }
    &::-webkit-scrollbar:horizontal {
      height: 0;
      width: 0;
      display: none;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      display: none;
    }
  }

  thead.ant-table-thead {
    th.ant-table-cell {
      font-size: 16px;
      line-height: 130%;
      font-weight: 700;
      color: #656b7e;
      white-space: nowrap;

      background: transparent;
      border: unset;
      border-bottom: 1px solid #34384c;
      padding-bottom: 24px;

      &:first-child {
        padding-left: 0;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  .ant-table-tbody {
    tr.ant-table-row:hover > td {
      background: transparent;
      border: unset;
    }
    td.ant-table-cell {
      color: #ffffff;
      white-space: nowrap;
      border: unset;
      &:first-child {
        padding-left: 0;
      }
      .table-cell-actions-coin-data {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
          width: 100%;
          max-width: 20px;
          margin-right: 10px;
          @include media-up(md) {
            max-width: 24px;
          }
        }

        .coin-data-label {
          div:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            margin-right: 14px;
            white-space: nowrap;
            @include media-up(md) {
              font-weight: 700;
              font-size: 16px;
              line-height: 21px;
              color: #fff;
            }
          }
        }
      }
      .recent-transaction-table-index {
        text-align: center;
        font-size: 16px;
        line-height: 130%;
        font-weight: 600;
        color: $text-main;
      }

      .recent-transaction-table-time,
      .recent-transaction-table-amount,
      .recent-transaction-table-action {
        text-align: center;
        font-size: 14px;
        line-height: 130%;
        font-weight: 500;
        color: $text-main;
      }

      .recent-transaction-table-currency {
        display: flex;
        align-items: center;

        font-size: 14px;
        line-height: 130%;
        font-weight: 500;
        color: $text-main;

        img {
          width: 24px;
          margin-right: 8px;
        }
      }
      .recent-transaction-table-status {
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: $text-main;
        text-transform: capitalize;
        height: 24px;
        padding: 4px 10px;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        &[data-status='HOLDING'] {
          background-color: #ffb051;
        }

        &[data-status='UNSTAKE'] {
          background-color: #44c99c;
        }

        &[data-status='COMPLETED'] {
          background-color: #232530;
        }
      }
      .recent-transaction-table-period {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .recent-transaction-table-view {
        text-align: center;
      }
      .recent-transaction-table-action {
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: $text-main;
        text-transform: capitalize;

        height: 24px;
        padding: 4px 10px;
        border-radius: 4px;
        background-color: #ffb051;
      }
    }
    tr.ant-table-placeholder:hover > td {
      background: transparent;
      border: unset;
    }
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
}
