@import './mixins/class';
@import './mixins/mixins';
@import './mixins/breakpoints';

$menu-height-desktop: 100px;
$menu-height-mobile: 80px;

$text-main: #ffffff;

$primary: red;
$secondary: #000000;

$success: #31d0aa;
$failure: #ed4b9e;
$warning: #ff4d4f;

$link: #03aef3;

$background-card: #1e1f25;
