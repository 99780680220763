@import 'assets/css/variables.scss';

.wallet-list-container {
  .create-wallet {
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;

    button {
      width: fit-content;
    }
  }
  .table-wallet-list-desktop {
    .coin-data {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      img {
        width: 100%;
        max-width: 24px;
        margin-right: 10px;
        @include media-up(md) {
          max-width: 30px;
        }
      }

      .coin-data-label {
        div:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          margin-right: 14px;
          white-space: nowrap;
          @include media-up(md) {
            font-size: 20px;
          }
        }
        div:last-child {
          color: #a5adcf;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          white-space: nowrap;
          @include media-up(md) {
            font-size: 16px;
          }
        }
      }
    }
    .actions {
      white-space: nowrap;
      button {
        color: #a5adcf;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: 30px;

        background: unset;
        border: unset;
        cursor: pointer;

        svg {
          fill: #01fbff;
          margin-right: 14px;
        }

        &:hover:not(&[data-disable='true']) {
          color: #fff;
        }
        &:active:not(&[data-disable='true']) {
          opacity: 0.7;
        }

        &[data-disable='true'] {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
    .ant-table-thead tr {
      display: none;
      @include media-up(md) {
        display: table-row;
      }
    }
  }
  .wallet-list-mobile {
    .wallet-item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      margin: 10px 0;
      border: 1px solid #333;
      border-radius: 10px;

      .coin-data {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
          width: 100%;
          max-width: 30px;
          margin-right: 10px;
        }

        .coin-data-label {
          div:first-child {
            font-weight: 600;
            font-size: 16px;
            line-height: 14px;
            line-height: 20px;
            white-space: nowrap;
            @include media-up(md) {
              font-size: 20px;
            }
          }
          div:last-child {
            color: #a5adcf;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            white-space: nowrap;
            @include media-up(md) {
              font-size: 16px;
            }
          }
        }
      }
      .wallet-item-right {
        display: flex;
        flex-flow: column nowrap;
        .balance {
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;

          max-width: 50vw;
          display: flex;
          align-items: center;
          @include media-down(sm) {
            flex-direction: column;
            align-items: flex-start;
          }

          > span {
            color: #a5adcf;
            font-size: 14px;
            padding-left: 4px;
            &:last-child {
              @include media-down(sm) {
                font-size: 10px;
              }
            }
          }
        }
        .actions {
          white-space: nowrap;
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          button {
            color: #a5adcf;
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            margin-left: 10px;

            padding: 2px 6px;
            background: unset;
            border: 1px solid pink;
            border-radius: 10px;
            cursor: pointer;
            svg {
              fill: #01fbff;
              width: 14px;
              margin-right: 6px;
            }

            &:hover {
              color: #fff;
            }
            &:active {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
