@import 'assets/css/variables.scss';

.private-footer {
  width: 100%;
  min-height: 70px;

  display: none;
  @include media-up(lg) {
    display: block;
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    width: 100%;
    list-style: none;
    margin: 0;

    li {
      padding: 20px;

      a {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
