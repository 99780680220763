.warp-deposit-form {
  width: 100%;
  padding: 24px;
  background-color: #010406;

  .go-back {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 30px;

    @media (min-width: 991px) {
      margin-bottom: 40px;
    }
  }

  .deposit-form {
    background: #1e1f25;
    border-radius: 12px;
    padding: 20px;
    max-width: 1000px;
    margin: auto;

    .box-select-deposit {
      margin-bottom: 34px;

      .item-form {
        margin-bottom: 22px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 40px;

        .item-form {
          margin-bottom: 35px;
        }
      }
    }

    .box-qr-code-title {
      margin-bottom: 30px;

      p {
        &:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          color: #ffffff;
          margin-bottom: 8px;
        }

        &:last-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #a5adcf;
          margin-bottom: 0;
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 0;

        p {
          &:first-child {
            font-size: 18px;
            line-height: 27px;
          }

          &:last-child {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .box-qr-code {
      display: flex;
      flex-direction: column;

      .box-qr-code-address {
        background: #141416;
        box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
        border-radius: 12px;
        padding: 10px;
        line-height: 0;
        width: 100px;
        margin: auto;
        margin-bottom: 8px;

        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .sub-title-qr-code {
        text-align: center;

        p {
          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 130%;
            color: #ffffff;
            margin-bottom: 4px;
          }

          &:last-child {
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;
            color: #777e91;
            margin-bottom: 0;
          }
        }
      }

      @media (min-width: 991px) {
        background: #23262f;
        padding: 35px 25px;
        border-radius: 16px;

        .box-qr-code-address {
          height: 100%;
          width: 150px;
          order: 2;
          padding: 20px;
        }

        .sub-title-qr-code {
          order: 1;
          margin-bottom: 32px;

          p {
            &:first-child {
              font-size: 18px;
              line-height: 27px;
            }
            &:last-child {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .box-attention {
      margin-bottom: 32px;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          padding: 0 0 0 27px;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 11px;
            height: 11px;
            background: #ffffff;
            border-radius: 50%;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            color: #a5adcf;

            span {
              font-weight: 700;
              background: #ffffff;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 60px;

        ul {
          li {
            p {
              font-size: 16px;
            }
          }
        }
      }
    }

    .box-expected {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4px;

      .item-expected {
        max-width: 50%;
        width: 50%;
        margin-bottom: 16px;

        p {
          &:first-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #ffffff;
            margin-bottom: 0;
          }

          &:last-child {
            font-weight: 700;
            font-size: 12px;
            line-height: 24px;
            color: #a5adcf;
            margin-bottom: 0;
          }
        }

        // &:last-child {
        //   p {
        //     &:last-child {
        //       line-height: 130%;
        //       background: linear-gradient(90deg, #52ffc0 0%, #00c291 100%);
        //       -webkit-background-clip: text;
        //       -webkit-text-fill-color: transparent;
        //       background-clip: text;
        //       text-fill-color: transparent;
        //     }
        //   }
        // }
      }

      @media (min-width: 991px) {
        margin-bottom: 30px;

        .item-expected {
          margin-bottom: 29px;

          p {
            &:first-child {
              font-size: 18px;
              line-height: 27px;
            }

            &:last-child {
              font-size: 16px;
            }
          }
        }
      }
    }

    .title-table-history {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 10px;

      @media (min-width: 991px) {
        font-size: 25px;
        margin-bottom: 16px;
      }
    }

    .table-history {
      p {
        margin-bottom: 0;
      }

      .ant-table {
        overflow: auto;

        .ant-table-container {
          min-width: 350px;
        }
      }
      .ant-table,
      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th {
        background: transparent;
      }

      .ant-table-thead > tr > th {
        border-bottom: 1px solid #34384c;

        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #5d6588;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .ant-table-tbody > tr > td {
        border-bottom: unset;
      }

      .ant-table-thead > tr > th:last-child,
      .ant-table-tbody > tr > td:last-child {
        text-align: right;
        padding-right: 0;
      }

      .ant-table-thead > tr > th:first-child,
      .ant-table-tbody > tr > td:first-child {
        padding-left: 0;
      }

      .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #a5adcf;
      }

      .amount {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: right;
        color: #ffffff;
      }

      .box-action-txid {
        > div {
          &:first-child {
            display: flex;
            align-items: center;

            p {
              margin-bottom: 0;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #ffffff;
                margin-right: 16px;
              }

              &:last-child {
                font-weight: 700;
                font-size: 8px;
                line-height: 100%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 62px;
                height: 15px;
                border-radius: 3px;

                &.pending {
                  color: #f9aa4b;
                  background: #ffe6c8;
                }

                &.completed {
                  color: #44c99c;
                  background: #d5f3e9;
                }
              }
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #ffffff;
        }

        @media (min-width: 991px) {
          > div {
            &:first-child {
              p {
                &:last-child {
                  font-size: 12px;
                  line-height: 16px;
                  width: 95px;
                  height: 24px;
                }
              }
            }
          }
        }
      }

      .action {
        color: #ffffff;
      }

      .txid {
        color: #ffffff;
      }

      @media (min-width: 991px) {
        .time {
          font-size: 16px;
          line-height: 24px;
        }

        .amount {
          font-size: 16px;
          line-height: 24px;
        }

        .action {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .txid {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }

    @media (min-width: 991px) {
      padding: 55px 90px;

      .deposit-form-top {
        margin-bottom: 24px;
      }
    }
  }
}
