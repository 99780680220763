@import 'assets/css/variables.scss';

.stake-list-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 16px;

  @include media-up(md) {
    column-gap: 24px;
    row-gap: 24px;
  }

  @include media-up(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 48px;
  }
}
