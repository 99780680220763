.modal-2fa {
  .ant-modal-body {
    text-align: center;
  }

  .canvas-qrcode {
    width: 100%;
    height: 100%;
    max-width: 204px;
    max-height: 204px;
    margin: 0 auto 20px;
    border: 2px solid white;
  }

  .text-note {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 20px 0;
    max-width: 300px;
    margin: 0 auto;
  }
}
