@import 'assets/css/variables.scss';

.Maintenance {
  padding: 0 15px;

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    text-align: center;
    @include media-up(sm) {
      width: fit-content;
      max-width: 90%;
    }
    & > img {
      max-height: 40px;
      margin: 16px 24px;
      object-fit: contain;
    }
    & > h2 {
      color: #fff;
      font-weight: bold;
      white-space: nowrap;
    }
    & > p {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px;
    }
  }
}
