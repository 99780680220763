.warp-service {
  position: relative;
  max-width: 1150px;
  width: 100%;
  padding: 40px 15px;
  margin: auto;
  overflow: hidden;

  .warp-service-content {
    position: relative;
    max-width: 800px;
    margin: auto;

    .service-group {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .service-group-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .service-item {
          width: 100%;
          position: relative;
          cursor: pointer;
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }

          .service-item-content {
            display: flex;
            width: 100%;

            > div {
              &:first-child {
                margin-right: 24px;

                img {
                  width: 53px;
                }
              }

              &:last-child {
                p {
                  &:first-child {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    margin-bottom: 1px;
                  }

                  &:last-child {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #ffffff;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: 500px) {
        .service-group-item {
          .service-item {
            .service-item-content {
              > div {
                &:last-child {
                  p {
                    &:first-child {
                      font-size: 24px;
                      line-height: 36px;
                    }

                    &:last-child {
                      font-size: 15px;
                      line-height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: 991px) {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        .service-group-item {
          margin-bottom: 0;

          .service-item {
            max-width: 350px;
            animation: rotateAround 10s linear infinite;
            margin-bottom: 0;

            &:last-child {
              .service-item-content {
                left: 45%;
                position: relative;
              }
            }

            .service-item-content {
              align-items: center;

              > div {
                &:first-child {
                  img {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .service-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      z-index: 3;
      width: 30%;
      display: none;

      .button-primary {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        min-width: 150px;
      }

      img {
        margin-bottom: 30px;
        width: 100%;
      }

      @media (min-width: 991px) {
        display: block;
      }
    }

    .warp-service-content-background {
      position: relative;
      text-align: center;
      margin-bottom: 50px;

      .service-center {
        display: block;
      }

      > img {
        &.background {
          width: 100%;
          max-width: 786px;
          height: auto;
        }
      }

      .halo {
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          z-index: 0;

          &:nth-child(1) {
            transform: translate(-80%, -70%);
            animation: opacity 8s infinite;
          }

          &:nth-child(2) {
            transform: translate(-15%, -30%);
          }
        }
      }

      .icon-circle {
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;

          &:nth-child(1) {
            width: 7%;
            transform: translate(-600%, -650%);
            --translateX: -600%;
            --translateY: -650%;
            animation: UpAndDown 4s infinite;
          }

          &:nth-child(2) {
            width: 4%;
            transform: translate(-950%, -400%);
            --translateX: -950%;
            --translateY: -400%;
            animation: UpAndDown 3s infinite;
          }

          &:nth-child(3) {
            width: 5.5%;
            transform: translate(-890%, 150%);
            --translateX: -890%;
            --translateY: 150%;
            animation: UpAndDown 2s infinite;
          }

          &:nth-child(4) {
            width: 5.5%;
            transform: translate(600%, -750%);
            --translateX: 600%;
            --translateY: -750%;
            animation: UpAndDown 1s infinite;
          }

          &:nth-child(5) {
            width: 8%;
            transform: translate(550%, 100%);
            --translateX: 550%;
            --translateY: 100%;
            animation: UpAndDown 5s infinite;
          }
        }
      }

      @media (min-width: 450px) {
        margin-bottom: 0;
      }

      @media (min-width: 991px) {
        margin-bottom: 0;

        .service-center {
          display: none;
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding: 60px 15px;
  }
}

@keyframes rotateAround {
  0% {
    transform: rotate(0deg) translateX(5px) translateY(5px) rotate(0deg);
  }
  25% {
    transform: rotate(90deg) translateX(5px) translateY(5px) rotate(-90deg);
  }
  50% {
    transform: rotate(180deg) translateX(5px) translateY(5px) rotate(-180deg);
  }
  75% {
    transform: rotate(270deg) translateX(5px) translateY(5px) rotate(-270deg);
  }
  100% {
    transform: rotate(360deg) translateX(5px) translateY(5px) rotate(-360deg);
  }
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes UpAndDown {
  0% {
    transform: translate(var(--translateX), var(--translateY));
  }
  50% {
    transform: translate(var(--translateX), calc(var(--translateY) - 5px));
  }
  100% {
    transform: translate(var(--translateX), var(--translateY));
  }
}
