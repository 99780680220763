.input-text-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  input {
    height: 43px;
    border: 1px solid #34384c;
    box-sizing: border-box;
    border-radius: 5px;
    color: #ffffff;
    background: transparent;
    padding: 10px 16px;
    width: 100%;
    outline: none;
  }

  @media (min-width: 991px) {
    margin-bottom: 32px;
    width: 100%;

    p {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
}

.ant-form-item-has-error .input-text-item input {
  border-color: #ff4d4f;
}
