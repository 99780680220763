@import 'assets/css/variables.scss';

.modal-staking {
  > button {
    top: 24px;

    @include media-up(lg) {
      top: 30px;
    }
  }
  .ant-modal-content {
    @include media-down(md) {
      padding-top: 24px;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .modal-staking-content {
    min-height: 300px;
    display: flex;
    // flex-flow: row wrap;
    flex-wrap: wrap;
    flex-direction: column;
    @media (min-width: 991px) {
      padding: 24px;
      flex-direction: row;
    }
    .modal-staking-left {
      margin-bottom: 24px;

      @include media-up(lg) {
        width: 50%;
        margin-bottom: unset;
        padding-right: 32px;
      }

      .modal-staking-helper {
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        text-transform: capitalize;
        width: fit-content;
        position: relative;

        @media (min-width: 991px) {
          font-size: 32px;
        }
        .modal-staking-helper-loo {
          color: #f9aa4b;
          font-weight: 400;
          font-size: 10px;
          line-height: 130%;
          text-align: center;

          width: 100%;
          max-width: 70px;
          padding: 4px;
          background: #ffe6c8;
          border-radius: 5px;

          position: absolute;
          top: 50%;
          left: 120%;
          transform: translateY(-50%);

          &::before {
            content: '';
            position: absolute;
            left: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: 0px;
            height: 0px;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-right: 4px solid #ffe6c8;
          }
        }
      }
      .modal-staking-coin-logo {
        display: flex;
        align-items: center;
        margin-top: 16px;

        @include media-up(md) {
          margin-top: 24px;
        }

        img {
          width: 24px;
          height: 24px;
          margin-right: 16px;

          @include media-up(md) {
            width: 48px;
            height: 48px;
          }
        }
        & > div {
          color: #ffffff;
          font-weight: 700;
          font-size: 14px;
          line-height: 130%;

          @include media-up(md) {
            font-size: 18px;
          }
        }
      }
      .modal-staking-des {
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        margin-top: 16px;

        @include media-up(md) {
          font-size: 16px;
        }
      }
      .modal-staking-duration {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .modal-duration-text {
          color: #ffffff;
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
          margin-top: 6px;

          @include media-up(md) {
            font-size: 16px;
          }
        }
        .modal-staking-duration-number {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          & > div {
            margin: 6px 0 0 12px;
            background-color: #232530;
            border-radius: 6px;

            @include media-up(md) {
              margin: 6px 0 0 24px;
            }

            &[data-active='true'] {
              background: #1a1f32;

              span {
                color: #567dff;
              }
            }

            span {
              color: #656b7e;
              font-weight: 500;
              font-size: 12px;
              line-height: 130%;

              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              white-space: nowrap;
              padding: 4px 8px;
              border-radius: 8px;
              height: 32px;

              @include media-up(md) {
                padding: 8px;
                font-size: 14px;
              }
            }
          }
        }
      }
      .modal-staking-input {
        width: 100%;
        margin-top: 16px;
        @media (min-width: 991px) {
          margin-top: 24px;
        }
        .modal-staking-input-label {
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;

          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @include media-up(md) {
            font-size: 16px;
          }

          .modal-staking-input-label-suffix {
            display: flex;
            flex-direction: row;
            color: #567dff;

            span:first-child {
              font-weight: 400;
              font-size: 12px;
              line-height: 130%;
              margin-right: 24px;

              @include media-up(md) {
                font-size: 14px;
              }
            }
            span:last-child {
              font-weight: 600;
              font-size: 12px;
              line-height: 130%;

              @include media-up(md) {
                font-size: 14px;
              }
            }
          }
        }

        .modal-staking-input-input {
          width: 100%;
          height: 40px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;

          padding: 10px 14px;
          margin-top: 16px;
          border-radius: 8px;
          background-color: #232530;

          @include media-up(md) {
            padding: 10px 18px;
            height: 46px;
          }

          input {
            color: #fff;
            font-weight: 500;
            font-size: 14px;

            width: 100%;
            background: transparent;
            border: unset;
            outline: unset;

            &::placeholder {
              color: #656b7e;
            }

            &:hover {
              border: unset;
              outline: unset;
            }
          }

          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }

          span {
            color: #ffffff;
            font-size: 14px;
            line-height: 24px;
          }
          .token-name {
            color: #c4c4c4;
            font-size: 14px;
            line-height: 130%;
            padding-right: 16px;
            border-right: 2px solid #34384c;
          }
          .btn-max {
            margin-left: 8px;

            button {
              font-weight: 400;
              position: relative;
              align-items: center;
              font-weight: 600;
              justify-content: center;
              letter-spacing: 0.03em;
              line-height: 1;
              opacity: 1;
              outline: 0px;
              display: inline-flex;
              align-items: center;
              transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
              height: 24px;
              font-size: 14px;
              padding: 0px 8px;
              background-color: transparent;
              // border: 2px solid rgb(31, 199, 212);
              box-shadow: none;
              color: #c4c4c4;
              cursor: pointer;
              border: none;
            }
          }
        }
      }
      .amount-limit {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        @include media-up(lg) {
          margin-top: 16px;
        }

        & > div {
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          line-height: 130%;

          @include media-up(md) {
            font-size: 16px;
          }

          span {
            margin-left: 4px;
          }
        }
      }
    }
    .modal-staking-right {
      @include media-up(lg) {
        width: 50%;
        margin-top: 60px;
        padding-left: 48px;
        border-left: 1px solid #34384c;
      }

      .modal-staking-right-title {
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;

        @include media-up(md) {
          font-size: 24px;
        }
      }
      .modal-staking-right-step {
        margin-top: 16px;

        .step-item {
          color: #ffffff;
          font-weight: 400;
          font-size: 14px;
          line-height: 130%;
          margin-bottom: 16px;

          position: relative;
          display: flex;
          justify-content: space-between;
        }
      }
      .error-mess {
        color: #ef466f;
        padding: 8px 0 0;
      }

      .modal-staking-right-apr {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #34384c;

        & > div {
          color: #ffffff;
          font-weight: 400;
          line-height: 130%;
          font-size: 14px;
        }
      }
      .modal-staking-right-agreement {
        color: #ffffff;
        font-weight: 400;
        line-height: 130%;
        font-size: 14px;
        margin-top: 24px;

        @include media-up(lg) {
          margin-top: 66px;
        }

        .ant-checkbox {
          top: 0.5em;
          margin-right: 8px;
        }

        .ant-checkbox-inner {
          width: 16px;
          height: 16px;
          background: rgba(47, 50, 65, 0.5);
          border: 1px solid #34384c;
          border-radius: 5px;

          @include media-up(md) {
            width: 22px;
            height: 22px;
          }

          &::after {
            width: 7px;
            height: 11px;
            top: 50%;
            left: 26%;
            border: 3px solid #21dba4;
            border-top: 0;
            border-left: 0;
          }
        }
        .ant-checkbox-checked {
          &::after {
            border-radius: 5px;
          }
        }
        span {
          &:last-child {
            font-size: 12px;

            @include media-up(md) {
              font-size: 14px;
            }
          }
        }
      }
      .modal-staking-actions {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        button {
          width: 40%;
          background: linear-gradient(90deg, #0075fe 0%, #00c2fe 100%);
          color: $text-main;
          padding: 10px 20px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-staking-helper {
    font-size: 32px;
  }
}
