.modal-qrcode-deposit {
  .ant-modal-header {
    border-bottom: none;
    background: transparent;
    display: none;

    .ant-modal-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 27px;
      color: #ffffff;
    }

    @media (min-width: 991px) {
      display: block;
    }
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 0;

    .modal-deposit-qs-code {
      width: 100%;
      height: 100%;
      max-width: 204px;
      max-height: 204px;
      margin: 0 auto;
      margin-bottom: 16px;
      border: 2px solid white;
    }

    p {
      font-weight: 400;
      font-size: 10px;
      line-height: 150%;
      color: #777e91;
      margin-bottom: 48px;

      &.title-modal {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #ffffff;
        margin-bottom: 4px;
      }

      @media (min-width: 991px) {
        font-size: 14px;

        &.title-modal {
          display: none;
        }
      }
    }

    .modal-scan-action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-scan-action-bg {
    position: absolute;
    height: 70%;
    width: 70%;
    background: radial-gradient(53.9% 53.9% at 77.01% 30.21%, rgba(31, 195, 231, 0.84375) 0%, #1f34e7 100%);
    opacity: 0.9;
    filter: blur(250px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -1;
  }

  .ant-modal-footer {
    display: none;
  }
}
