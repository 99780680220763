.warp-banner {
  position: relative;
  max-width: 1150px;
  padding: 40px 15px;
  width: 100%;
  margin: auto;

  img {
    width: 100%;
    margin-bottom: 32px;
  }

  @media (min-width: 991px) {
    padding: 60px 15px;
  }
}
