@import 'assets/css/variables.scss';

// Form item Input
.form-item-input {
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .wrapper-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    span {
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;

      @include media-up(sm) {
        font-size: 18px;
      }
    }
    &.ant-form-item-required span {
      position: relative;
      &:before {
        content: '*';
        color: #ff4d4f;
        display: inline-block;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        right: -10px;
      }
    }

    button {
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      text-align: center;

      max-width: 80px;
      height: 30px;
      padding: 0 20px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @include media-up(sm) {
        font-size: 14px;
        height: 40px;
        max-width: 110px;
      }

      &:hover {
        opacity: 0.7;
      }
      &:active {
        opacity: 1;
      }
    }

    .btn-active {
      color: #ffffff;
      background: transparent;
      border: 1px solid #a5adcf;
      border-radius: 50px;
      cursor: pointer;
    }
    .btn-disable {
      color: #fa2256;
      background: transparent;
      border: 1px solid #fa2256;
      border-radius: 50px;
      cursor: pointer;
    }
  }

  // .ant-form-item-explain-error {
  .ant-form-item-explain {
    font-size: 12px;
    padding-left: 32px;
    @include media-up(sm) {
      font-size: 14px;
    }
  }
}

// Form item Checkbox
.form-item-checkbox {
  width: 100%;
  padding: 10px 0 10px;
  @include media-up(sm) {
  }

  .ant-checkbox {
    top: 0.4em;
  }

  .ant-form-item-explain {
    padding-left: 32px;
  }

  .ant-checkbox-wrapper {
    span:last-child {
      color: #a5adcf;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
      background: rgba(47, 50, 65, 0.5);
      border: 1px solid #34384c;
      border-radius: 5px;
      &::after {
        width: 7px;
        height: 11px;
        top: 50%;
        left: 26%;
        border: 3px solid #21dba4;
        border-top: 0;
        border-left: 0;
      }
    }
    .ant-checkbox-checked {
      &::after {
        border-radius: 5px;
      }
    }
  }
}
