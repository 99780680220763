.box-input {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 615px;

  input {
    display: block;
    width: 615px;
    height: 55px;
    max-width: 100%;
    background: rgba(47, 50, 65, 0.5);
    border: 1px solid #34384c;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 21.5px 168px 21.5px 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    outline: none;
  }

  .button-primary {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 22px;
    max-width: 50%;
    padding: 8px 16px;
  }

  &[data-size='small'] {
    input {
      height: 45px;
    }

    .btn-primary {
      padding: 6px 12px;
    }

    @media (min-width: 991px) {
      input {
        height: 55px;
        font-size: 16px;
      }

      .btn-primary {
        font-size: 16px;
        line-height: 160%;
        padding: 10px 20px;
      }
    }
  }

  &[data-size='standard'] {
    input {
      height: 45px;
    }

    .btn-primary {
      padding: 6px 18px;
    }

    @media (min-width: 991px) {
      input {
        height: 54px;
        font-size: 16px;
      }

      .btn-primary {
        font-size: 16px;
        line-height: 160%;
        padding: 8px 30px;
      }
    }
  }

  &[data-size='larger'] {
    input {
      height: 55px;
    }

    .btn-primary {
      padding: 8px 16px;
    }

    @media (min-width: 991px) {
      input {
        height: 67px;
        font-size: 16px;
      }

      .btn-primary {
        font-size: 16px;
        line-height: 160%;
        padding: 16px 32px;
      }
    }
  }

  @media (min-width: 991px) {
    input {
      height: 67px;
      font-size: 16px;
    }

    .btn-primary {
      font-size: 16px;
      line-height: 160%;
      padding: 16px 32px;
    }
  }
}
