.box-recent-transaction {
  margin-top: 32px;

  .recent-transaction-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 24px;
  }
}
