@import 'assets/css/variables.scss';
.card-exchange {
  .exchange-swap-icon {
    display: flex;
    justify-content: center;

    .icon-down {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
  .exchange-swap-action {
    display: flex;
    justify-content: center;
    & > button {
      max-width: 200px;
      margin: 12px 0 40px;
      @include media-down(sm) {
        margin: 12px 0 10px;
      }
    }
  }
}
