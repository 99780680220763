.input-select-item {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  @media (min-width: 991px) {
    p {
      font-size: 18px;
    }
  }
}
