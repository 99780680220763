.box-form-control {
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .ant-select {
    width: 100% !important;

    .ant-select-selector {
      height: 46px;
      border: 1px solid #34384c;
      box-sizing: border-box;
      color: #ffffff;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      border-radius: 5px !important;
      padding: 0 0 0 32px;
    }

    .ant-select-selector,
    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      background: rgba(47, 50, 65, 0.5) !important;
    }

    .ant-select-arrow {
      color: #ffffff;
      right: 32px;
    }

    .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;

      img {
        width: 30px;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 16px;
      }
    }
  }

  @media (min-width: 991px) {
    p {
      font-size: 18px;
    }

    .ant-select {
      .ant-select-selector {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}

.ant-select-item {
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;

  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
  }
}
