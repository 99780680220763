.warp-page-withdraw {
  padding: 20px 15px 0;
  max-width: 100%;
  .go-back {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 30px;

    @media (min-width: 991px) {
      margin-bottom: 40px;
    }
  }
  .page-withdraw-content {
    max-width: 1000px;
    margin: auto;

    .withdraw-content-form {
      background: #1e1f25;
      border-radius: 12px;
      padding: 20px;
      max-width: 1000px;
      margin: auto;

      .box-input-select {
        margin-bottom: 32px;

        .input-select-item {
          &:nth-child(2) {
            .ant-select-selection-item {
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              color: #0075fe;
            }
          }
        }
      }

      .box-input-text {
        margin-bottom: 8px;
      }

      .box-attention {
        margin-bottom: 32px;

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          li {
            position: relative;
            padding: 0 0 0 24px;
            margin-bottom: 8px;

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #a5adcf;

              span {
                font-weight: 600;
                line-height: 150%;
                color: #ffffff;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 8px;
              width: 8px;
              background: #ffffff;
              border-radius: 50%;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .box-discamler {
        padding: 26px 16px;
        background: rgba(47, 50, 65, 0.5);
        border-radius: 10px;

        display: flex;

        > div {
          &:first-child {
            margin-right: 16px;
          }

          &:last-child {
            flex: 1;
          }
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
          color: #ffffff;

          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            color: #ffffff;
            margin-bottom: 4px;
          }
        }
      }

      .withdraw-actions {
        .error-mess {
          color: #ef466f;
          padding: 32px 0 0;
        }

        & > button {
          margin-top: 20px;
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding: 42px 15px 0;

    .page-withdraw-content {
      .withdraw-content-form {
        padding: 55px 90px;
        .box-input-select {
          margin-bottom: 48px;
        }

        .box-input-text {
          margin-bottom: 32px;
        }

        .box-attention {
          margin-bottom: 48px;

          ul {
            li {
              padding: 0 0 0 28px;
              margin-bottom: 14px;

              p {
                font-size: 16px;

                span {
                  font-weight: 700;
                  line-height: 24px;
                }
              }

              &::before {
                height: 11px;
                width: 11px;
              }
            }
          }
        }

        .box-discamler {
          padding: 30px 36px;

          p {
            font-size: 12px;

            &:first-child {
              font-size: 16px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
