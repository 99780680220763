.item-recent-transaction {
  width: 100%;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #34384c;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  > div {
    p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &[data-status='COMPLETED'] {
        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #44c99c;
          background: #d5f3e9;
          border-radius: 3px;
          min-width: 80px;
          min-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &:first-child {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #5d6588;

        &:first-child {
          font-weight: 600;
          font-size: 16px;
          color: #a5adcf;
        }
      }
      a {
        font-size: 16px;
      }
    }

    &:last-child {
      a {
        display: block;

        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #01fbff;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;

        &:nth-child(3) {
          font-weight: 600;
        }
      }
    }
  }
}
