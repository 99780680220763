.modal-verification-code {
  .ant-modal-content {
    padding: 60px 30px;

    p {
      text-align: left;
    }

    .ant-modal-header {
      margin-bottom: 40px;
      padding: 0;
    }

    .ant-modal-body {
      padding: 0;

      .title-modal {
        margin-bottom: 32px;
      }

      .list-steps-verify {
        margin-bottom: 36px;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            position: relative;
            padding-left: 30px;
            padding-bottom: 48px;

            &.current {
              &::before {
                content: '';
                background: #f4f8ff;
                border: 1px solid #4884fa;
              }
            }

            &.active {
              &::before {
                content: '✔';
                background: #4884fa;
                border: 1px solid #4884fa;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 18px;
              width: 18px;
              background: #1b1d28;
              border: 1px solid #c9c9c9;
              box-sizing: border-box;
              border-radius: 50%;
              z-index: 1;
            }

            &::after {
              content: '';
              position: absolute;
              left: 9px;
              top: 0;
              width: 1px;
              height: 100%;
              background-color: #ffffff;
              z-index: 0;
            }

            &:last-child {
              padding-bottom: 0;

              &::after {
                content: unset;
              }
            }

            p {
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              color: #ffffff;
              margin-bottom: 0;
            }
          }
        }

        @media (min-width: 991px) {
          ul {
            li {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }

      .modal-verification-code-note {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #a5adcf;
        margin-bottom: 16px;

        @media (min-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .input-code-container {
        margin-bottom: 10px;
      }

      @media (min-width: 991px) {
        .modal-verification-code-note {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 11px;
        }

        .input-code-container {
          margin-bottom: 16px;
        }
      }
    }

    .modal-scan-action {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}
