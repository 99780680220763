@import 'assets/css/variables.scss';

.stake-header-helper-container {
  padding: 10px;
  background: #1a252e;
  // backdrop-filter: blur(14px);
  border-radius: 14px;
  padding: 16px 12px;

  @include media-up(xm) {
    padding: 16px 24px;
  }

  @include media-up(xl) {
    padding: 0 24px 0 64px;
  }

  .stake-header-helper-body {
    background-size: 224px auto;
    background-repeat: no-repeat;
    background-position: bottom;

    @include media-up(md) {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
    }
  }
  .stake-header-helper-content {
    max-width: 100%;
    padding-bottom: 245px;

    @include media-up(md) {
      max-width: 50%;
      padding: 72px 0;
    }
  }
  .stake-header-helper-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 8px;

    @include media-up(md) {
      font-size: 32px;
    }
  }
  .stake-header-helper-des {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #ffffff;

    @include media-up(md) {
      font-size: 16px;
    }
  }
  .stake-header-helper-action {
    margin-top: 8px;
    width: 100%;
    max-width: 136px;
    height: 34px;

    border-color: #73f8fd;
    border-radius: 8px;

    font-size: 14px;
    font-weight: 600;
    color: #73f8fd;

    @include media-up(md) {
      margin-top: 32px;
      height: 51px;
      max-width: 233px;

      font-size: 24px;
    }
  }
}
