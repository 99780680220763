@import 'assets/css/variables.scss';

.warp-page-account {
  background-color: $secondary;
  padding: 20px 15px 0;
  max-width: 100%;

  .page-account-content {
    max-width: 1000px;
    margin: auto;

    .box-avatar {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 36px;

      .avatar-content-left {
        width: fit-content;
        display: flex;
        align-items: center;

        .avatar-image {
          position: relative;
          height: 68px;
          width: 68px;
          border: 2px solid #0075fe;
          border-radius: 128px;
          padding: 5px;
          margin-right: 24px;

          img {
            &:first-child {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 128px;
            }

            &:last-child {
              position: absolute;
              width: 20px;
              bottom: 0;
              right: 0;
              cursor: pointer;

              &:active {
                opacity: 0.7;
              }
            }
          }
        }

        .avatar-info {
          input {
            width: 100%;
            max-width: 200px;
            background-color: transparent;
            border: 1px solid #34384c;
            color: #fcfcfd;
            font-weight: 700;
            background: transparent;
            &:hover,
            &:focus {
              outline: none;
            }
          }
          p {
            width: fit-content;
            &:first-child {
              display: flex;
              align-items: center;

              font-weight: 600;
              font-size: 16px;
              line-height: 130%;
              color: #fcfcfd;
              margin-bottom: 4px;

              img {
                width: 17px;
                margin-left: 7px;
                cursor: pointer;

                &:active {
                  opacity: 0.7;
                }
              }
            }

            &:last-child {
              font-weight: 700;
              font-size: 12px;
              line-height: 16px;
              color: #fcfcfd;
              margin-bottom: 0;
            }
          }
        }
      }

      .avatar-content-right {
        display: none;
      }

      @media (min-width: 991px) {
        .avatar-content-left {
          .avatar-image {
            height: 84px;
            width: 84px;
          }

          .avatar-info {
            p {
              &:first-child {
                font-size: 18px;
                margin-bottom: 8px;
              }

              &:last-child {
                font-size: 14px;
              }
            }
          }
        }

        .avatar-content-right {
          display: block;
        }
      }
    }

    .box-info-input {
      margin-bottom: 32px;

      .input-width-pen {
        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 27px;
          color: #ffffff;
          margin-bottom: 0;
        }

        .box-input {
          position: relative;
          max-width: 100%;

          input {
            border: 1px solid #34384c;
            box-sizing: border-box;
            border-radius: 5px;
            height: 43px;
            width: 100%;
            padding-left: 15px;
            padding-right: 40px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #fcfcfd;
            background-color: transparent;
          }

          img {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            cursor: pointer;
          }

          .ant-select-selector {
            height: 43px;
            width: 100%;
            background-color: transparent;
            border: 1px solid #34384c;
            color: #fcfcfd;
            font-weight: 700;
            font-size: 14px;

            .ant-select-selection-item {
              line-height: 43px;
            }

            input {
              border: unset;
              padding: 0;
            }
          }

          .ant-picker {
            background-color: transparent;
            color: #fcfcfd;
            border: 1px solid #34384c;

            .ant-picker-suffix,
            .ant-picker-clear {
              opacity: 0;
            }

            input {
              background-color: transparent;
              border: none;
            }
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 42px;

        .input-width-pen {
          p {
            font-size: 18px;
            margin-bottom: 16px;
          }

          .box-input {
            input {
              padding-left: 20px;
              padding-right: 45px;
              font-size: 18px;
            }

            img {
              width: 19px;
            }

            .ant-select-selector {
              font-size: 18px;
            }
          }
        }
      }
    }

    .box-info-password {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .password-left {
        width: 100%;

        p {
          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            color: #ffffff;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #a5adcf;
          }
        }
      }

      .password-right {
        display: none;
      }

      @media (min-width: 991px) {
        margin-bottom: 42px;

        .password-left {
          p {
            &:first-child {
              font-size: 18px;
              margin-bottom: 8px;

              button {
                display: none;
              }
            }

            &:last-child {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .password-right {
          display: block;
        }
      }
    }

    .box-rate-sercurity {
      margin-bottom: 34px;

      > a {
        &:last-child {
          .rate-sercurity-item {
            margin-bottom: 0;
          }
        }
      }

      .rate-sercurity-item {
        background: rgba(62, 60, 60, 0.2);
        border-radius: 12px;
        padding: 24px 20px;
        margin-bottom: 36px;

        .rate-sercurity-item-name {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            color: #ffffff;
            margin-left: 16px;
          }
        }

        .rate-sercurity-item-description {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #a5adcf;
          }
        }
      }

      @media (min-width: 991px) {
        margin-bottom: 0;

        .rate-sercurity-item {
          padding: 36px 50px;

          .rate-sercurity-item-name {
            margin-bottom: 24px;

            p {
              margin-left: 30px;
              font-size: 18px;
            }
          }

          .rate-sercurity-item-description {
            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .log-out {
      text-align: center;

      @media (min-width: 991px) {
        display: none;
      }
    }
  }

  @media (min-width: 991px) {
    padding: 42px 15px 0;
  }
}
