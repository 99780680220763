@import 'assets/css/variables.scss';

.public-layout-container {
  width: 100%;
  min-height: 100vh;
  background: $secondary;

  .desktop-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    z-index: 10;

    display: none;
    @include media-up(lg) {
      display: flex;
    }

    .header-left {
      .header-logo {
        position: absolute;
        left: 96px;
        top: 25px;
        cursor: pointer;
        img {
          width: 100%;
          max-width: 140px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
    .header-right {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;

      position: absolute;
      right: 96px;
      top: 27px;
    }
  }
}
