@import 'assets/css/variables.scss';

.stake-container {
  overflow: hidden;
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;

  @include media-down(sm) {
    padding: 16px;
  }

  @include media-down(xs) {
    padding: 6px;
  }
}
