@import 'assets/css/variables.scss';

.profile-account {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  & > .background-cover {
    width: 40px;
    height: 40px;
    padding: 2px;
    margin-right: 10px;
    border-radius: 100%;
    background: linear-gradient(to left, rgba(0, 117, 254, 1) 0%, rgba(0, 194, 254, 1) 100%);
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  & > span {
    color: #fcfcfd;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.dropdown-logout {
  background: rgba(66, 124, 142, 0.9);
  border-radius: 12px;
  padding: 3px 0;

  display: flex;
  align-items: center;
  flex-direction: column;

  a {
    fill: #fff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;

    width: 100%;
    display: flex;
    align-items: center;

    &:not(:first-child):not(:last-child) {
      border-top: 1px solid rgba(214, 198, 198, 0.2);
    }
    &:last-child {
      border-top: 2px solid rgba(58, 36, 36, 0.2);
    }

    &:hover {
      fill: rgb(154, 190, 239);
      color: rgb(154, 190, 239);
    }

    svg {
      max-width: 18px;
      margin-right: 10px;
    }
  }
}
