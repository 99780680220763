@import 'assets/css/variables.scss';

.custom-modal {
  background: rgba(27, 29, 40, 0.95);
  border-radius: 12px;
  padding: 0;

  @include media-down(md) {
    width: 100% !important;
    max-width: 100%;
    margin: unset;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    position: absolute;
    top: unset !important;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ant-modal-content {
    padding: 12px 20px 20px;
    background: transparent;
    box-shadow: unset;
    border: unset;
    max-height: 90vh;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-modal-close {
    top: 14px;
    right: 25px;

    .ant-modal-close-x {
      width: 30px;
      height: 30px;
      line-height: 0;

      border: 2px solid #353945;
      border-radius: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      .anticon {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;
    background: transparent;
    @include media-down(sm) {
      padding: 16px 0;
    }
    .ant-modal-title {
      color: #ffffff;
      font-weight: 600;
      font-size: 32px;
      line-height: 27px;
      width: 100%;
      @include media-down(sm) {
        font-size: 22px;
      }
    }
  }

  .ant-modal-body {
    @include media-down(sm) {
      padding: 24px 0;
    }
  }

  .ant-modal-footer {
    border: unset;
    border-radius: unset;
    text-align: center;

    button {
      max-width: 200px;
    }
  }
}
