@import 'assets/css/variables.scss';

.button-secondary {
  color: #ffffff;

  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  width: 100%;
  height: 47px;
  padding: 3px;
  background: transparent;
  border: 1px solid #34384c;
  border-radius: 100px;
  outline: unset;
  cursor: pointer;
  position: relative;

  &[data-size='small'] {
    font-size: 10px;
    line-height: 20px;
    height: 18px;
    @include media-up(sm) {
      font-size: 12px;
      height: 26px;
    }
  }
  &[data-size='standard'] {
    font-size: 14px;
    line-height: 22px;
    height: 36px;
    @include media-up(sm) {
      font-size: 16px;
      height: 46px;
    }
  }
  &[data-size='larger'] {
    font-size: 16px;
    line-height: 24px;
    height: 56px;
    @include media-up(sm) {
      font-size: 18px;
      height: 66px;
    }
  }

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }

  &[disabled],
  &[data-loading='true'] {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &[data-hoverable='false'] {
    opacity: 1;
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
