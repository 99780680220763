@import 'assets/css/variables.scss';

.navigation-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 60px;
  max-width: 92%;
  margin: 0 auto 10px;
  background: rgba(62, 60, 60, 0.9);
  // backdrop-filter: blur(4px);
  border-radius: 50px;

  --clr: #fff;

  ul {
    display: flex;
    margin: 0 auto;
    width: fit-content;
    li {
      display: flex;
      align-items: center;

      position: relative;
      list-style: none;
      width: 70px;
      height: 60px;
      z-index: 1;

      @include media-down(xm) {
        width: 65px;
      }

      @include media-down(xs) {
        width: 55px;
      }

      a {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        font-weight: 500;

        svg {
          width: 24px;
          position: relative;
          display: block;
          text-align: center;
          transition: 0.5s;
          fill: var(--clr);
        }
      }
      span {
        position: absolute;
        color: var(--clr);
        font-weight: 600;
        letter-spacing: 0.05em;
        transition: 0.5s;
        opacity: 0;
        transform: translateY(20px);

        font-size: 12px;
        @include media-down(xs) {
          font-size: 10px;
        }
      }
    }
    li.active a svg {
      transform: translateY(-30px);
    }
    li.active a span {
      opacity: 1;
      transform: translateY(12px);
    }
    .indicator {
      position: absolute;
      top: -50%;
      width: 60px;
      height: 60px;
      background: linear-gradient(90deg, #0075fe 0%, #00c2fe 100%);
      border-radius: 50%;
      transition: 0.5s;
    }
    li:nth-child(1).active ~ .indicator {
      transform: translateX(calc(105px * 0 + 5px));

      @include media-down(xm) {
        transform: translateX(calc(105px * 0 + 2px));
      }

      @include media-down(xs) {
        transform: translateX(calc(105px * 0 - 2px));
      }
    }

    li:nth-child(2).active ~ .indicator {
      transform: translateX(calc(105px * 1 - 30px));

      @include media-down(xm) {
        transform: translateX(calc(105px * 1 - 37px));
      }

      @include media-down(xs) {
        transform: translateX(calc(105px * 1 - 52px));
      }
    }

    li:nth-child(3).active ~ .indicator {
      transform: translateX(calc(105px * 2 - 65px));

      @include media-down(xm) {
        transform: translateX(calc(105px * 2 - 77px));
      }

      @include media-down(xs) {
        transform: translateX(calc(105px * 2 - 102px));
      }
    }

    li:nth-child(4).active ~ .indicator {
      transform: translateX(calc(105px * 2 + 5px));

      @include media-down(xm) {
        transform: translateX(calc(105px * 2 - 12px));
      }

      @include media-down(xs) {
        transform: translateX(calc(105px * 2 - 47px));
      }
    }

    li:nth-child(5).active ~ .indicator {
      transform: translateX(calc(105px * 3 - 30px));

      @include media-down(xm) {
        transform: translateX(calc(105px * 3 - 53px));
      }

      @include media-down(xs) {
        transform: translateX(calc(105px * 2 + 7px));
      }
    }
  }
}
