@import 'assets/css/variables.scss';

.search-wrapper {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-right: 40px;
  background: rgba(47, 50, 65, 0.5);
  border-radius: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    cursor: pointer;
  }

  input {
    color: #fff;
    font-family: 'Graphik';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    background: transparent;
    border: unset;
    outline: unset;

    &::placeholder {
      font-family: 'Graphik';
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #5d6588;
    }
  }
}
