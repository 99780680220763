.warp-page-security {
  background-color: transparent;
  padding: 20px 15px 0;
  max-width: 100%;

  .page-security-content {
    max-width: 1000px;
    margin: auto;

    .page-security-content-header {
      background: rgba(62, 60, 60, 0.2);
      padding: 34px 16px;
      border-radius: 12px;
      margin-bottom: 32px;

      .box-kyc {
        padding-bottom: 6px;
        margin-bottom: 6px;
        border-bottom: 1px solid #34384c;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #a5adcf;

          &:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #ffffff;
          }

          button {
            min-width: 120px;
          }
        }
      }

      .info-kyc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .info-item {
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;

            &:first-child {
              color: #a5adcf;
            }

            &:last-child {
              color: #ffffff;
            }
          }
        }
      }
    }

    .page-security-content-body {
      .security-item {
        margin-bottom: 32px;

        .btn-disable {
          border-color: rgba(250, 34, 86, 0.5);
          color: #fa2256;
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #a5adcf;

          &:first-child {
            font-weight: 600;
            font-size: 14px;
            line-height: 27px;
            color: #ffffff;

            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
          }
        }
      }
    }

    @media (min-width: 991px) {
      .page-security-content-header {
        padding: 33px 40px;
        margin-bottom: 60px;

        .box-kyc {
          padding-bottom: 20px;
          margin-bottom: 20px;

          p {
            font-size: 16px;

            &:first-child {
              font-size: 24px;
            }
          }
        }
        .info-kyc {
          .info-item {
            span {
              font-size: 14px;
            }
          }
        }
      }

      .page-security-content-body {
        padding: 0 25px;
        .security-item {
          margin-bottom: 36px;

          p {
            font-size: 16px;
            line-height: 27px;

            &:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 991px) {
    padding: 42px 15px 0;
  }
}
