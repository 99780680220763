@import 'assets/css/variables.scss';

.stake-list-card-container {
  .stake-card {
    padding: 1px;
    border-radius: 20px;
    min-height: 85px;

    @include media-up(md) {
      min-height: 133px;
    }

    .stake-card-content {
      padding: 8px;
      background-color: #161616;
      border-radius: 20px;
      line-height: 130%;

      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      @include media-up(xm) {
        padding: 8px 12px;
      }

      @include media-up(md) {
        padding: 16px;
        height: 100%;
        width: 100%;
      }
    }

    .stake-card-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      line-height: 130%;

      @include media-up(md) {
        font-weight: 600;
        font-size: 16px;
      }
    }
    .stake-card-balance {
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px;
      color: $text-main;
      margin-top: 8px;
      line-height: 130%;

      @include media-up(sm) {
        font-size: 24px;
        margin-top: 10px;
      }
    }
    .stake-card-Link {
      display: flex;
      align-items: center;
      margin-top: 8px;

      @include media-up(md) {
        margin-top: 24px;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        color: #01fbff;
        line-height: 130%;
        margin-right: 8px;
      }
    }
  }
}
